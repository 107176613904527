import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import SocialMedia from "../../Components/SocialMedia";
export default function Contact() {
    return(
        <>
            <Header/>
            <SocialMedia/>
            <Footer/>
        </>
    )
}