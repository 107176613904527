export default
{
    menu: "Menu",
    home: "Home",
    about: "About",
    skills: "Skills",
    experiences: "Notable Experiences",
    projects: "Projects",
    contact: "Contact",
    aboutMe: "About Me",
    fullstack: "Fullstack Developer",
    gameProgrammer: "Game Programmer",
}