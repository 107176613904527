import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Profile from "../../Components/Profile";
export default function About() {
    return(
        <>
            <Header/>
            <Profile/>
            <Footer/>
        </>
    )
}
