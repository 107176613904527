export default
{
    menu: "Menu",
    home: "Início",
    about: "Sobre",
    skills: "Skills",
    experiences: "Experiências",
    projects: "Projetos",
    contact: "Contato",
    aboutMe: "Sobre Mim",
    fullstack: "Desenvolvedor Fullstack",
    gameProgrammer: "Programador de Jogos",
}