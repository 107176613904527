import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Main from "../../Components/Main";
export default function Home() {
    return (
        <>
            <Header/>
            <Main/>
            <Footer/>
        </>
    );
}
